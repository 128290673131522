import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  ResponseStatus,
  ResponseVip,
  mapResponseStatus,
  mapResponseVip,
  responseStatusArray,
  responseVipArray,
} from '../../../shared/enumeration/ResponseStatus'
import { IParams, ISelectValue } from '../../../shared/shared-interfaces'
import SModal from '../../shared/Modal/SModal'
import EyeIcon from '../../shared/icons/EyeIcon'
import EyeOffIcon from '../../shared/icons/EyeOffIcon'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { INewUser, IUser } from '../../../shared/model/user.model'
import { AppDispatch } from '../../../store'
import { ToastSuccess } from '../../shared/toast/Toast'
import {
  createEntity,
  getEntitiesAgent,
  updateEntity,
} from './usersManagement.api'
import { fetching, resetEntity, userSelectors } from './usersManagement.reducer'
import { permissionSelectors } from '../SystemSetting/PermissionGroup/permission.reducer'
import {
  mapRoleToString,
  SystemRole,
  systemRoleArray,
} from '@/shared/enumeration/role'
import Select, { SingleValue, components } from 'react-select'
import { IPermission } from '@/shared/model/permission.model'
import { bankSelectors } from '../BankManagement/banksManagement.reducer'
import { getEntities as getEntitiesBank } from '../BankManagement/banksManagement.api';
import { IBank } from '@/shared/model/bank.model';

interface IUserUpdateProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  userObj?: IUser
}

export const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img
          src={props.data.image}
          alt={props.data.label}
          style={{ width: 100, height: 30, marginRight: 10 }}
        />
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  )
}

export const CustomSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <img
          src={props.data.image}
          alt={props.data.label}
          style={{ width: 100, height: 30, marginRight: 10 }}
        />
        <div>{props.data.label}</div>
      </div>
    </components.SingleValue>
  )
}

const UserUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector((state: RootState) => state.usersReducer)
  const { user } = useSelector((state: RootState) => state.authentication)
  const { updateEntitySuccess } = initialState
  const { visible, setVisible, userObj } = props
  const handleOnClose = () => {
    setVisible(false)
  }

  const { listAgent, filterState } = initialState

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(
    false,
  )

  const banks = useSelector(bankSelectors.selectAll);


  useEffect(() => {
    dispatch(getEntitiesAgent({ page: 1, limit: 100, role: SystemRole.AGENT }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false)
      ToastSuccess(
        userObj ? 'Cập nhật tài khoản thành công' : 'Tạo tài khoản thành công',
      )
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    dispatch(getEntitiesAgent({ page: 1, limit: 100, role: SystemRole.AGENT }))
    dispatch(getEntitiesBank({ page: 1, limit: 100 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues: INewUser = {
    fullName: '',
    username: '',
    email: '',
    money: 0,
    password: '',
    confirmPassword: '',
    permissionGroupId: '',
    cardName: '',
    cardNumber: '',
    bankName: '',
    status: ResponseStatus.ACTIVE,
    vip: ResponseVip.VIP1,
    role: SystemRole.USER,
    agentId: SystemRole.AGENT === user?.role ? user?.username : '',
    passwordWithdraw: '',
  }

  const returnPermissionItem = (
    permissionList: IUser[],
    selectPermission?: IUser | null,
  ) => {
    return permissionList.map((item) => {
      return {
        value: item.username,
        label: item.username,
      }
    })
  }


  const returnBankItem = (items: IBank[], selectBank?: IBank | null) => {
    return items.map((item) => {
      return {
        value: item.bankId,
        label: item.name,
        image: item.avatar,
      }
    })
  }

  const customPermissionItems: ISelectValue<string>[] = returnPermissionItem(
    listAgent,
    userObj,
  )

  const customBankItems: any = returnBankItem(
    banks
  )

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .trim().min(6, 'Số ký tự tối thieu 6 ký tự')
      .required('Không được để trống')
      .matches(/^\S+$/, 'Tên đăng nhập không được chứa khoảng trống')
      .matches(
        /^\w+$/,
        `Tên đăng nhập không được viết dấu hoặc chứa ký tự đặc biệt`,
      )
      .max(20, 'Số ký tự tối đa là 20 ký tự'),
    money: Yup.number().required('Không được để trống').nullable(),
    status: Yup.string().trim().required('Không được để trống'),
  })

  const returnOption = (
    optionValue: string | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    console.log(optionValue, 'optionValue')

    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => value === optionValue)
  }

  return (
    <SModal
      visible={visible}
      onClose={handleOnClose}
      backdrop="static"
      className="custom-modal"
      size="lg"
    >
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching())
          if (userObj) {
            dispatch(updateEntity(value as IUser))
          } else {
            dispatch(createEntity(value))
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>
                {userObj ? 'Chỉnh sửa tài khoản' : 'Tạo tài khoản'}
              </CModalHeader>
              <CModalBody>
                <CRow className="g-4">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên tài khoản
                    </CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="username"
                      autoComplete="off"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.username && touched.username}
                      readOnly={userObj ? true : false}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.username && touched.username
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.username}
                    </CFormFeedback>
                  </CCol>

                  {user?.role === SystemRole.ADMIN && (
                    <CCol xs="12" onClick={(e) => e.stopPropagation()}>
                      <CFormLabel className="mb-6 label-gray-700">
                        Đại lý
                      </CFormLabel>
                      <Select
                        // menuIsOpen={true}
                        defaultValue={null}
                        className="custom-select form-height-44"
                        classNamePrefix="react-select"
                        value={
                          returnOption(
                            values.agentId,
                            customPermissionItems,
                          ) as any
                        }
                        onChange={(
                          newValue: SingleValue<ISelectValue<string>>,
                        ) => {
                          setFieldValue(`agentId`, newValue?.value || '')
                        }}
                        noOptionsMessage={() => <>Không có đại lý nào</>}
                        id={'agentId'}
                        options={customPermissionItems}
                        placeholder={'Chọn Đại Lý'}
                        isClearable={true}
                        closeMenuOnSelect={true}
                        menuPosition={'absolute'}
                        name="agentId"
                      />
                      <CFormFeedback
                        invalid
                        className={
                          !!errors.permissionGroupId &&
                            touched.permissionGroupId
                            ? 'd-block'
                            : 'd-none'
                        }
                      >
                        {errors.permissionGroupId}
                      </CFormFeedback>
                    </CCol>
                  )}

                  <CCol xs={12}>
                    <CRow>
                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          VIP
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="vip"
                          value={values.vip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.vip && touched.vip}
                        >
                          {responseVipArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapResponseVip[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.vip && touched.vip ? 'd-block' : 'd-none'
                          }
                        >
                          {errors.vip}
                        </CFormFeedback>
                      </CCol>

                      <CCol xs="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Loại tài khoản
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.role && touched.role}
                        >
                          {systemRoleArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapRoleToString[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.role && touched.role ? 'd-block' : 'd-none'
                          }
                        >
                          {errors.role}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên ngân hàng
                    </CFormLabel>

                    <Select
                      // menuIsOpen={true}
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.bankName as any,
                          customBankItems,
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>,
                      ) => {
                        setFieldValue(`bankName`, newValue?.value || '')
                      }}
                      noOptionsMessage={() => <>Không có ngân hàng nào</>}
                      id={'bankName'}
                      options={customBankItems}
                      placeholder={'Chọn Ngân Hàng'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      name="bankName"
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                    />

                    <CFormFeedback
                      invalid
                      className={
                        !!errors.bankName && touched.bankName
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.bankName}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Tên tài khoản ngân hàng
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="cardName"
                      value={values.cardName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.cardName && touched.cardName}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.cardName && touched.cardName
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.cardName}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Số tài khoản ngân hàng
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="cardNumber"
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.money && touched.cardNumber}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.cardNumber && touched.cardNumber
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.cardNumber}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Email
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.money && touched.email}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.email && touched.email
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.email}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">
                      Mật khẩu rút tiền
                    </CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="passwordWithdraw"
                      value={values.passwordWithdraw}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.passwordWithdraw && touched.passwordWithdraw}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.passwordWithdraw && touched.passwordWithdraw
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.passwordWithdraw}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs={12}>
                    <CRow>
                      <CCol xs="12" md="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Mật khẩu
                        </CFormLabel>

                        <CInputGroup className="input-end-group">
                          <CFormInput
                            className="form-height-44"
                            name="password"
                            autoComplete="off"
                            type={passwordVisible ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={!!errors.password && touched.password}
                          />{' '}
                          <CInputGroupText
                            id="search-addon"
                            className="cursor-pointer form-height-44"
                            onClick={(e) => {
                              e.stopPropagation()
                              setPasswordVisible(!passwordVisible)
                            }}
                          >
                            {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </CInputGroupText>
                        </CInputGroup>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.password && touched.password
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.password}
                        </CFormFeedback>
                      </CCol>

                      <CCol xs="12" md="6">
                        <CFormLabel className="mb-6 label-gray-700">
                          Xác nhận mật khẩu
                        </CFormLabel>
                        <CInputGroup className="input-end-group">
                          <CFormInput
                            className="form-height-44"
                            name="confirmPassword"
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              !!errors.confirmPassword &&
                              touched.confirmPassword
                            }
                          />
                          <CInputGroupText
                            id="search-addon"
                            className="cursor-pointer form-height-44"
                            onClick={(e) => {
                              e.stopPropagation()
                              setConfirmPasswordVisible(!confirmPasswordVisible)
                            }}
                          >
                            {confirmPasswordVisible ? (
                              <EyeOffIcon />
                            ) : (
                              <EyeIcon />
                            )}
                          </CInputGroupText>
                        </CInputGroup>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.confirmPassword && touched.confirmPassword
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.confirmPassword}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                  {/* ) : (
                    ''
                  )} */}

                  <CCol xs="12">
                    <CRow>
                      <CCol xs="12">
                        <CFormLabel className="mb-6 label-gray-700">
                          Trạng thái
                        </CFormLabel>
                        <CFormSelect
                          className="form-height-44"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.status && touched.status}
                        >
                          {responseStatusArray.map((item, index) => (
                            <option key={index} value={item}>
                              {mapResponseStatus[item]}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.status && touched.status
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.status}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm()
                    setVisible(false)
                  }}
                >
                  Huỷ
                </CButton>
                <CButton
                  className="btn-custom minw-120 primary-500"
                  type="submit"
                >
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  )
}

export default UserUpdate
